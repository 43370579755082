<template>
  <v-container class="content">
    <v-row class="pt-2">
      <v-col cols="12" class="align-center">
        <h1>{{getTakeExamDetail.exam_meta.title}}</h1>
        <br>
        <h3>Tên học viên: {{getTakeExamDetail.user.full_name}}</h3>
      </v-col>
    </v-row>
    <hr class="pt-4">
    <v-row class="pt-4"
        v-for="(question, index) in getListQuestionReview"
        :key="'b' + index"
    >
      <!-- question content box  -->
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <div class="headline">Câu {{ question.index }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <vuetify-audio
                v-if="question.audio && question.audio.src"
                color="success"
                :file="question.audio.src"
            ></vuetify-audio>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mr-6">
          <v-col class="pa-0 ma-0 mr-6">
            <div v-html="question.content"></div>
          </v-col>
        </v-row>
      </v-col>
      <!-- write answer   -->
      <v-col>
        <div class="green--text font-weight-bold">Đáp án của bạn:</div>
        <div v-html="question.take_answers.content" v-if="question.take_answers && question.take_answers.content"></div>
        <div class="green--text font-weight-bold">Đáp án mẫu:</div>
        <div v-html="question.answers[0].content" v-if="question.answers && question.answers[0].content"></div>
      </v-col>
    </v-row>
    <hr>
    <v-row class="pt-4 justify-center">
      <v-col cols="6">
            <h4 class="float-left mr-5 pt-2">Tổng điểm:</h4>
            <hnr-input
                class="float-left pa-0 mr-5"
                type="number"
                style="width: 100px;"
                :text="getTakeExamDetail.exam_score"
                v-model="getTakeExamDetail.exam_score"
                :reverse="true"
            ></hnr-input>
            <hnr-button
                class="ma-0 pt-1"
                @hnrClickEvent="sendScore()"
                rounded
                width="100"
                text="Gửi điểm"
                color="#000000"
                white-text="true"
            >
            </hnr-button>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <span class="red--text">{{messageErrors}}</span>
    </v-row>
  </v-container>
</template>

<script>

import HnrButton from "@/components/elements/hnr-button";
import HnrInput from "@/components/elements/hnr-input";
import { UPDATE_SCORE_EXAM_WRITTING,
        GET_TAKE_EXAM_DETAIL,
        GET_QUESTION_REVIEW_EXAM}
  from "@/store/exams.module";
import {mapGetters} from "vuex";

export default {
  name: "mark-exam",
  components: {
    HnrInput,
    HnrButton
  },
  data: () => ({
    messageErrors: "",
  }),
  computed: {
    ...mapGetters({
      getListQuestionReview: "getListQuestionReview",
      getTakeExamDetail: "getTakeExamDetail",
    })
  },
   async created() {
     this.getList();
     this.$store.dispatch(GET_TAKE_EXAM_DETAIL, {
       exam_schedule_id: this.$route.query.exam_schedule_id,
       exam_id: this.$route.query.exam_id,
       exam_config_id: this.$route.query.exam_config_id,
       user_id: this.$route.query.user_id,
     });
  },
  methods: {
    getList() {
      this.$store.dispatch(GET_QUESTION_REVIEW_EXAM, {
        exam_schedule_id: this.$route.query.exam_schedule_id,
        exam_id: this.$route.query.exam_id,
        exam_config_id: this.$route.query.exam_config_id,
        user_id: this.$route.query.user_id,
      });
    },
    async sendScore() {
      if (isNaN(this.getTakeExamDetail.exam_score)) {
        this.messageErrors = "Trường Tổng điểm chỉ cho nhập nhập số!";
      }
      else if (this.getTakeExamDetail.exam_score == "") {
        this.messageErrors = "Trường tổng điểm không được để trống!";
      }
      else if (this.getTakeExamDetail.exam_score > 100 || this.getTakeExamDetail.exam_score < 0) {
        this.messageErrors = "Điểm số vượt ngưỡng quy định!";
      } else {
        this.messageErrors = "";
        await this.$store.dispatch(UPDATE_SCORE_EXAM_WRITTING, {
          exam_schedule_id: this.$route.query.exam_schedule_id,
          exam_id: this.$route.query.exam_id,
          exam_config_id: this.$route.query.exam_config_id,
          user_id: this.$route.query.user_id,
          score: this.getTakeExamDetail.exam_score,
        });
        this.$toasted.success("Gửi điểm thành công!", {
          duration: 5000,
        })
        await this.$router.push({
          path: "studentByExamList",
          query: {
            exam_schedule_id: this.$route.query.exam_schedule_id,
          },
        });
      }
    }
  }
};
</script>
<style scoped>
.content {
  margin-left:50px;
}
</style>
